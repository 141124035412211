import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchallactivityLeads = createAsyncThunk(
  'allleads/fetchallactivityLeads',
  async (params) => {
    // let query = Object.keys(params)
    //   .map((k) => {
    //     if (typeof params[k] === 'object') {
    //       return Object.keys(params[k])
    //         .map((subKey) => {
    //           return `${k}[${subKey}]=${encodeURIComponent(params[k][subKey])}`;
    //         })
    //         .join('&');
    //     } else {
    //       return `${k}=${params[k]}`;
    //     }
    //   })
    //   .join('&');
    // const option = {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     authorization: localStorage.getItem('token_key'),
    //   },
    // };
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');

    const option = {
      method: 'GET',
      // body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    // const url = `${API_URL + 'api/v1/leadsactivity/'}?${query}`;
    const url = `${API_URL + 'api/v1/leadsactivity/?' + query}`;
    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);

const allleadsSlice = createSlice({
  name: 'allleads',
  initialState: {
    allleads: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchallactivityLeads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchallactivityLeads.fulfilled, (state, action) => {
      state.allleads = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchallactivityLeads.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default allleadsSlice.reducer;
