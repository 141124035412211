import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const deleteLeasingOffer = createAsyncThunk(
  'LeasingOfferDelete/deleteLeasingOffer',
  async (params) => {
    const option = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL + 'api/v1/leaseoffer/'}${params}`;

    const response = await callFetch(url, option);

    const value = await response;
    return value;
  }
);

const LeasingOfferSliceDelete = createSlice({
  name: 'LeasingOfferDelete',
  initialState: {
    leasingOfferDelete: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteLeasingOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteLeasingOffer.fulfilled, (state, action) => {
      state.leasingOfferDelete = action.payload.data;
      state.loading = false;
    });
    builder.addCase(deleteLeasingOffer.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default LeasingOfferSliceDelete.reducer;
