import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchSalesAgreement = createAsyncThunk(
  'salesAgreement/fetchSalesAgreement',
  async (params) => {
    let query = Object.keys(params)
      .map((k) => {
        if (typeof params[k] === 'object') {
          return Object.keys(params[k])
            .map((subKey) => {
              return `${k}[${subKey}]=${encodeURIComponent(params[k][subKey])}`;
            })
            .join('&');
        } else {
          return `${k}=${params[k]}`;
        }
      })
      .join('&');
    const option = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL + 'api/v1/salesagreement/'}?${query}`;
    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);

const allSalesAgreementSlice = createSlice({
  name: 'allSalesAgreement',
  initialState: {
    allSalesAgreement: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSalesAgreement.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSalesAgreement.fulfilled, (state, action) => {
      state.allSalesAgreement = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchSalesAgreement.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default allSalesAgreementSlice.reducer;
