import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchUnit = createAsyncThunk('Unit/fetchUnit', async (params) => {
  let query = Object.keys(params)
    .map((k) => {
      if (typeof params[k] === 'object') {
        return Object.keys(params[k])
          .map((subKey) => {
            return `${k}[${subKey}]=${encodeURIComponent(params[k][subKey])}`;
          })
          .join('&');
      } else {
        return `${k}=${params[k]}`;
      }
    })
    .join('&');

  const option = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token_key'),
    },
  };

  const url = `${API_URL}api/v1/unit/?${query}`;

  const response = await callFetch(url, option);

  const value = await response;
  return value;
});

const UnitSlice = createSlice({
  name: 'Unit',
  initialState: {
    unit: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUnit.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUnit.fulfilled, (state, action) => {
      state.unit = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchUnit.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default UnitSlice.reducer;
