import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchAllMainMasterDetails = createAsyncThunk(
  'master/fetchAllMainMasterDetails',
  async () => {
    const option = {
      method: 'GET',
      // body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL + 'api/v1/main_master'}`;
    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);

const mainMasterSlice = createSlice({
  name: 'mainMaster',
  initialState: {
    mainMaster: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllMainMasterDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllMainMasterDetails.fulfilled, (state, action) => {
      state.mainMaster = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchAllMainMasterDetails.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default mainMasterSlice.reducer;
