import { configureStore } from '@reduxjs/toolkit';
import propertyReducer from 'src/services/propertyApi/propertygetallAPI';
import loginReducer from 'src/services/loginApi/loginAPI';
import userReducer from 'src/services/userApi/usersgetallAPI';
import singleuserReducer from 'src/services/userApi/usersgetAPI';
import roleReducer from 'src/services/roleApi/rolesgetall';
import communityReducer from 'src/services/communityAPI/communitygetAPI';
import spareReducer from 'src/services/SparepartsAPI/sparePartsGetAll';
import procurementReducer from 'src/services/ProcurementAPI/procurementGetAll';
import unitGetall from 'src/services/unitmasterAPI/unitGetall';
import unitGetallLeasing from 'src/services/unitmasterAPI/UnitGetallLeasing';
import UnitUpdate from 'src/services/unitmasterAPI/unitUpdate';
import mainMasterReducer from 'src/services/masterAPI/mainMastergetAPI';
import UnitCreate from 'src/services/unitmasterAPI/UnitCreate';
import Contactgetall from 'src/services/ContactAPI/contactgetall';
import complaintsReducer from 'src/services/complaintsAPI/complaintsGetAllAPI';
import tasksreducer from 'src/services/supervisorTaskApi/TaskgetAll';
import dropdownlistAPI from 'src/services/dropdownAPI/dropdownlistAPI';
import profileReducer from '../../src/services/profileAPI/profileuseGet';
import fetchAllcalendar from 'src/services/CalendarAPI/calendar';
import dayCalendar from 'src/services/CalendarAPI/dayCalendar';
import fetchallLead from 'src/services/LeadsAPI/leadsgetall';
import notificationReducer from 'src/services/NotificationApi/NotificationGetAll';
// import activityReducer from 'src/services/ActivityAPI/fetchAllActivityDetails';
import TemplateGetAllSlice from 'src/services/EmailTemplateAPI/TemplateGetAll';
import fetchallactivityLeads from 'src/services/ActivityLeadsAPI/activityleadsgetall';
import allworkflowSlice from '../services/WorkflowAPI/workflowgetall';
import employeegetallSlice from '../services/EmployeeAPI/EmployeeGet';
import supplierSlice from '../services/SupplierAPI/fetchAllSupplier';
import allsetupSlice from '../services/SetupAPI/setupgetall';
import paymentplanSlice from '../services/PaymentPlanAPI/getPaymentPlanListApi';
import salespersonSlice from '../services/SalespersonAPI/getAllSalespersonsApi';
import AssetCategorySlice from '../services/AssetCategoryApi/AssetCategoryGetall';
import developerSlice from '../services/developerAPI/developerGet';
import assetSlice from '../services/AssetAPI/fetchAllAssetsApi';
import subAssetSlice from '../services/SubAssetAPI/fetchAllSubAssetApi';
import ResourceCategorySlice from '../services/ResourceCategoryApi/ResourceCategorygetall';
import CampaignSlice from '../services/CampaignAPI/CampiagnGetall';
import userMastergetallSlice from '../services/userMasterAPI/UserMasterGetall';
import profileuser from '../services/userMasterAPI/UserMasterGet';
import activityReducer from 'src/services/ActivityAPI/fetchAllActivityDetails';
import singleContactReducer from 'src/services/ContactAPI/constactget.js';
import leasingOfferCreateReducer from 'src/services/Leasing/LeaseOfferCreate';
import leasingOfferByIdReducer from 'src/services/Leasing/LeaseOfferGetById';
import leasingOfferGetAll from 'src/services/Leasing/LeaseOfferGetAll';
import leasingOfferCreateUpdate from 'src/services/Leasing/LeaseOfferUpdate';
import leasingOfferDeleteReducer from 'src/services/Leasing/LeaseOfferDelete';
import accessRightsReducer from './Slicer/accessRightsSlice';
import SalesOfferGetAllSlice from 'src/services/SalesAPI/SalesOfferAPI/fetchAllSalesOffersApi';
import SalesAgreementGetAllSlice from 'src/services/SalesAPI/SalesAgreementContractAPI/fetchAllSalesAgreementApi';
import leasingAgreementGetAll from 'src/services/Leasing/LeaseAgreementGetAll';
import leasingAgreementCreateReducer from 'src/services/Leasing/LeaseAgreementCreate';
import leasingAgreementDeleteReducer from 'src/services/Leasing/LeaseAgreementDelete';
import leasingAgreementUpdateReducer from 'src/services/Leasing/LeaseAgreementUpdate';
import leasingAgreementByIdReducer from 'src/services/Leasing/LeaseAgreementGetById';
import vatRegionReducer from 'src/services/VATRegionAPI/fetchAllVatRegionApi';
import vatMasterReducer from 'src/services/VATMasterAPI/fetchAllVatItemsApi';
import dealCancellationReducer from 'src/services/SalesAPI/DealCancellationAPI/fetchAllDealCancellationApi';
import ApprovalGatAllReducer from 'src/services/Approval/ApprovalGetAll';
import ApprovalFromCreateReducer from 'src/services/Approval/FromDetails/ApprovalFromCreate';
import ApprovalRoleCreateReducer from 'src/services/Approval/RoleDetails/ApprovalRoleCreate';
import ApprovalSendNotificationCreateReducer from 'src/services/Approval/SendNotificationsDetails/ApprovalSendNotificationCreate';
import userLoginReducer from './Slicer/userLoginSlicer';
import leasingAgreementFurnishingGetAll from 'src/services/Leasing/LeaseAgreementFurnishingGetAll';
import leasingAgreementServiceGetAll from 'src/services/Leasing/LeaseAgreementAdditionalServiceGetAll';
import leasingAgreementFurnishingDeleteReducer from 'src/services/Leasing/LeaseAgreementFurnishingDelete';
import leasingAgreementServiceDeleteReducer from 'src/services/Leasing/LeaseAgreementAdditionalServiceDelete';

import AuditTrailGetAllReducer from 'src/services/AuditTrail/AuditTrailGetAll';
const initialState = {
  sidebarShow: true,
  theme: 'light',
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    default:
      return state;
  }
};

const legacyReducer = (state = {}, action) => {
  return {
    ...state,
    legacy: changeState(state.legacy, action),
  };
};

export const store = configureStore({
  reducer: {
    Login: loginReducer,
    Property: propertyReducer,
    User: userReducer,
    Singleuser: singleuserReducer,
    Role: roleReducer,
    Community: communityReducer,
    SpareParts: spareReducer,
    Procurement: procurementReducer,
    Unit: unitGetall,
    UnitLeasing: unitGetallLeasing,
    leasingOfferDelete: leasingOfferDeleteReducer,
    leasingOfferUpdate: leasingOfferCreateUpdate,
    leasingOfferCreate: leasingOfferCreateReducer,
    leaingOfferById: leasingOfferByIdReducer,
    leasingOffer: leasingOfferGetAll,
    leasingAgreement: leasingAgreementGetAll,
    leasingAgreementFurnishing: leasingAgreementFurnishingGetAll,
    leasingAgreementService: leasingAgreementServiceGetAll,
    leasingAgreementById: leasingAgreementByIdReducer,
    leasingAgreementCreate: leasingAgreementCreateReducer,
    leasingAgreementUpdate: leasingAgreementUpdateReducer,
    leasingAgreementDelete: leasingAgreementDeleteReducer,
    leasingAgreementFurnishingDelete: leasingAgreementFurnishingDeleteReducer,
    leasingAgreementServiceDelete: leasingAgreementServiceDeleteReducer,
    templates: TemplateGetAllSlice,
    workflowlist: allworkflowSlice,
    unitcrt: UnitCreate,
    uniedit: UnitUpdate,
    MainMaster: mainMasterReducer,
    legacy: legacyReducer,
    contact: Contactgetall,
    singleContact: singleContactReducer,
    Complaints: complaintsReducer,
    Tasks: tasksreducer,
    Dropdown: dropdownlistAPI,
    userProfile: profileReducer,
    fetchAllcalendar: fetchAllcalendar,
    dayCalendar: dayCalendar,
    fetchallLead: fetchallLead,
    notificationdata: notificationReducer,
    Activity: activityReducer,
    LeadsActivity: fetchallactivityLeads,
    Employee: employeegetallSlice,
    Supplier: supplierSlice,
    PaymentPlan: paymentplanSlice,
    Salespersons: salespersonSlice,
    AssetCategory: AssetCategorySlice,
    Developer: developerSlice,
    Assets: assetSlice,
    SubAssets: subAssetSlice,
    ClientSetup: allsetupSlice,
    ResourceCategory: ResourceCategorySlice,
    Campaign: CampaignSlice,
    Usermaster: userMastergetallSlice,
    profileuser: profileuser,
    accessRights: accessRightsReducer,
    SalesOffers: SalesOfferGetAllSlice,
    SalesAgreements: SalesAgreementGetAllSlice,
    vatRegion: vatRegionReducer,
    vatMaster: vatMasterReducer,
    dealCancellation: dealCancellationReducer,
    Approval: ApprovalGatAllReducer,
    ApprovalFromCreate: ApprovalFromCreateReducer,
    ApprovalRoleCreate: ApprovalRoleCreateReducer,
    ApprovalSendNotificationCreate: ApprovalSendNotificationCreateReducer,
    UserloginSlice: userLoginReducer,
    AuditTrail: AuditTrailGetAllReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
