import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchRole = createAsyncThunk('user/fetchsingleUsers', async () => {
  const option = {
    method: 'GET',
    // body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('token_key'),
    },
  };
  const url = `${API_URL + 'api/v1/role/'}`;
  const response = await callFetch(url, option);
  const value = await response;
  return value;
});

const roleSlice = createSlice({
  name: 'roles',
  initialState: {
    roles: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchRole.fulfilled, (state, action) => {
      state.roles = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchRole.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default roleSlice.reducer;
