import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchSingledate = createAsyncThunk(
  'calendarday/fetchSingledate',
  async (params) => {
    const option = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL}api/v1/calendar/day?date=${params.date}&model_name=${params.model_name}`;

    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);

const calendardaySlice = createSlice({
  name: 'calendar',
  initialState: {
    calendarday: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSingledate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSingledate.fulfilled, (state, action) => {
      state.calendarday = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchSingledate.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default calendardaySlice.reducer;
