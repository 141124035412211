import React from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const Alertpopup = ({
  notification,
  vertical,
  horizontal,
  visible,
  setState,
}) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        // style={{ color: 'black' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={visible}
        autoHideDuration={5000}
        onClose={handleClose}
        message={notification}
        key={`${vertical}-${horizontal}`}
        action={action}
      />
    </Box>
  );
};

Alertpopup.propTypes = {
  notification: PropTypes.string.isRequired,
  vertical: PropTypes.string.isRequired,
  horizontal: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
};

export default Alertpopup;
