import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessRights: null,
};

const accessRightsSlice = createSlice({
  name: 'accessRights',
  initialState,
  reducers: {
    setAccessRights(state, action) {
      state.accessRights = action.payload;
    },
  },
});

export const { setAccessRights } = accessRightsSlice.actions;

export default accessRightsSlice.reducer;
