// Import necessary dependencies
import React, { Suspense, useEffect, useState } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CSpinner, useColorModes } from '@coreui/react';
import AuthGuard from './views/pages/login/AuthGuard';
import './Style/style.scss';
import './Style/bass.scss';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchDropdownlist } from 'src/services/dropdownAPI/dropdownlistAPI';
import { fetchAllCommunityDetails } from './services/communityAPI/communitygetAPI';
import { fetchAllComplaintsDetails } from './services/complaintsAPI/complaintsGetAllAPI';
import { fetchUnit } from './services/unitmasterAPI/unitGetall';
import { fetchProperty } from './services/propertyApi/propertygetallAPI';
// import { fetchusers } from './services/userApi/usersgetallAPI';
import io from 'socket.io-client';
import Alertpopup from './components/PopupModals/Alert';
import { fetchTemplates } from './services/EmailTemplateAPI/TemplateGetAll';
import { API_URL } from './utils/constant';
import packageJson from '../package.json';
import { fetchallWorkflow } from './services/WorkflowAPI/workflowgetall';
import { fetchallemployee } from './services/EmployeeAPI/EmployeeGet';
import { fetchallSetup } from './services/SetupAPI/setupgetall';
import { fetchAllSupplierDetails } from './services/SupplierAPI/fetchAllSupplier';
import { fetchAllSalespersonDetails } from './services/SalespersonAPI/getAllSalespersonsApi';
import { fetchAllAssetCategory } from './services/AssetCategoryApi/AssetCategoryGetall';
import { fetchusersMasterGetall } from './services/userMasterAPI/UserMasterGetall';
import { fetchRole } from './services/roleApi/rolesgetall';
import InactivityAlert from './components/PopupModals/InactivityAlert';
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Forgotpassword = React.lazy(
  () => import('./views/pages/Forgotpassword/forgotpassword')
);
const Restpassword = React.lazy(
  () => import('./views/pages/Resetpassword/Resetpassword')
);
const Emailverification = React.lazy(
  () => import('./views/pages/Check Your Email/Check_your_Email')
);
// const ENDPOINT = "http://143.244.141.6:3000";
const ENDPOINT = API_URL;
const App = () => {
  const version = packageJson.version;
  const dispatch = useDispatch();
  const { isColorModeSet, setColorMode } = useColorModes(
    'coreui-free-react-admin-template-theme'
  );
  const storedTheme = useSelector((state) => state.theme);
  // const [authenticated, setAuthenticated] = useState(false);
  const [newNotification, setNewNotification] = useState(null); // State for new notification content
  const [showAlert, setShowAlert] = useState(false);
  const userid = localStorage.getItem('user_id');
  const tokenkey = localStorage.getItem('token_key');
  // const isAuthenticated = () => {
  //   return localStorage.getItem('is_login') === 'true' || true;
  // };
  useEffect(() => {
    const socket = io(ENDPOINT);
    socket.emit('joinRooms', userid);
    socket.on('newNotification', (data) => {
      setNewNotification(data.content);
      setShowAlert(true);
    });
  }, []);

  const queryStrings = {
    pageSize: 'All',
  };

  // useEffect(() => {
  //   dispatch(fetchDropdownlist());
  //   dispatch(fetchAllCommunityDetails(queryStrings));
  //   dispatch(fetchAllComplaintsDetails(queryStrings));
  //   dispatch(fetchProperty(queryStrings));
  //   dispatch(fetchUnit(queryStrings));
  //   dispatch(fetchTemplates(queryStrings));
  //   dispatch(fetchallWorkflow(queryStrings));
  //   dispatch(fetchallemployee(queryStrings));
  //   dispatch(fetchallSetup(queryStrings));
  //   dispatch(fetchAllSalespersonDetails(queryStrings));
  //   dispatch(fetchAllSupplierDetails(queryStrings));
  //   dispatch(fetchAllAssetCategory(queryStrings));
  //   dispatch(fetchusersMasterGetall(queryStrings));
  //   dispatch(fetchRole(queryStrings));
  // }, [queryStrings]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
    const theme =
      urlParams.get('theme') &&
      urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0];
    if (theme) {
      setColorMode(theme);
    }

    if (isColorModeSet()) {
      return;
    }

    setColorMode(storedTheme);
  }, []);

  return (
    <HashRouter>
      <ToastContainer />
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner className="spinner-color" variant="grow" />
          </div>
        }
      >
        <Routes>
          <Route exact path="/login" element={<Login version={version} />} />
          <Route exact path="/" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/forgot_password" element={<Forgotpassword />} />
          <Route exact path="/reset_password" element={<Restpassword />} />
          <Route
            exact
            path="/email_verification"
            element={<Emailverification />}
          />
          <Route path="/404" element={<Page404 />} />
          <Route exact path="/500" element={<Page500 />} />
          {/*
          <Route
            path="/compliants"
            element={
              isAuthenticated ?
              <DefaultLayout />
              :
              <Login />
            }
          /> */}
          <Route
            path="*"
            element={
              <AuthGuard>
                <DefaultLayout />
              </AuthGuard>
            }
          />
        </Routes>
      </Suspense>
      <Alertpopup
        notification={newNotification}
        visible={showAlert}
        setState={setShowAlert}
      />
      <InactivityAlert />
    </HashRouter>
  );
};

export default App;
