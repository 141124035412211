import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchAllcalendar = createAsyncThunk(
  'calendar/fetchAllcalendar',
  async (params) => {
    const option = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL}api/v1/calendar/month?date=${params.date}&model_name=${params.model_name}`;
    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);

const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    calendardetails: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllcalendar.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllcalendar.fulfilled, (state, action) => {
      state.calendardetails = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchAllcalendar.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default calendarSlice.reducer;
