import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchLeaseAgreementService = createAsyncThunk(
  'getLeaseAgreementService/fetchLeaseAgreementService',
  async (params) => {
    const option = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL + 'api/v1/leaseservice/?leaseId='}${params}`;
    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);

const getLeaseAgreementServiceSlice = createSlice({
  name: 'getLeaseAgreementService',
  initialState: {
    getLeaseAgreementService: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLeaseAgreementService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLeaseAgreementService.fulfilled, (state, action) => {
      state.getLeaseAgreementService = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchLeaseAgreementService.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default getLeaseAgreementServiceSlice.reducer;
