/* eslint-disable no-unused-vars */
import { parseJSON, checkStatus } from '../utils/constant';

export default function callFetch(url, option = {}) {
  return fetch(url, option)
    .then(checkStatus)
    .then(parseJSON)
    .catch((err) => {
      let status = true;
      if (localStorage.getItem('token_key') !== null) {
        if (err.status === 401) {
          status = false;
          localStorage.removeItem('token_key');
          localStorage.removeItem('is_login');
          localStorage.removeItem('is_role');
          window.location.href = '/';
        }
      }
      return err;
    });
}
