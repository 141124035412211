import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const loginfetchUsers = createAsyncThunk(
  'login/loginfetchUsers',
  async (params) => {
    const option = {
      method: 'POST',
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json' },
    };
    // const url = `${API_URL + 'api/v1/auth/login'}`;
    const url = `${API_URL + 'api/v1/auth/userlogin'}`;
    const response = await callFetch(url, option);
    let value;
    if (response.status === 401) {
      value = await response.json();
    } else {
      value = await response;
    }
    return value;
  }
);

const loginSliceUser = createSlice({
  name: 'login',
  initialState: {
    login: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginfetchUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginfetchUsers.fulfilled, (state, action) => {
      state.login = action.payload.data;
      state.loading = false;
    });
    builder.addCase(loginfetchUsers.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default loginSliceUser.reducer;
