import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchUnitupdate = createAsyncThunk(
  'Unitupdate/fetchUnitupdate',
  async (params) => {
    const option = {
      method: 'PUT',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL + 'api/v1/unit/'}${params.id}`;

    const response = await callFetch(url, option);

    const value = await response;
    return value;
  }
);

const UnitSliceupdate = createSlice({
  name: 'Unitupdate',
  initialState: {
    Unitupdate: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUnitupdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUnitupdate.fulfilled, (state, action) => {
      state.Unitupdate = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchUnitupdate.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default UnitSliceupdate.reducer;
