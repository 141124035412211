// AuthGuard.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('is_login') === 'true';
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [navigate]);

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AuthGuard;
