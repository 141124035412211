import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const createLeasingOffer = createAsyncThunk(
  'LeasingOfferCreate/createLeasingOffer',
  async (params) => {
    const option = {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL + 'api/v1/leaseoffer/'}`;
    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);

const LeasingOfferSlicePost = createSlice({
  name: 'LeasingOfferCreate',
  initialState: {
    LeasingOfferCreate: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createLeasingOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createLeasingOffer.fulfilled, (state, action) => {
      state.LeasingOfferCreate = action.payload.data;
      state.loading = false;
    });
    builder.addCase(createLeasingOffer.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default LeasingOfferSlicePost.reducer;
