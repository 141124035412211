import { createSlice } from '@reduxjs/toolkit';
import navigation from '../../components/PageLayout/_nav';

const initialState = {
  userloginpage: null,
  filternev: [],
};

const UserloginSlice = createSlice({
  name: 'userloginpage',
  initialState,
  reducers: {
    setUserloginpage(state, action) {
      let payload = action.payload;
      state.userloginpage = action.payload;
    },
    resetuserloginpage(state, action) {
      state.userloginpage = null;
    },
    setFilternev(state, action) {
      state.filternev = action.payload;
    },
  },
});

export const { setUserloginpage, resetuserloginpage, setFilternev } =
  UserloginSlice.actions;

export default UserloginSlice.reducer;
