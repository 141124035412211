import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchLeaseAgreementFurnishing = createAsyncThunk(
  'getLeaseAgreementFurnishing/fetchLeaseAgreementFurnishing',
  async (params) => {
    const option = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL + 'api/v1/leasefurnshing/?leaseId='}${params}`;
    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);

const getLeaseAgreementFurnishingSlice = createSlice({
  name: 'getLeaseAgreementFurnishing',
  initialState: {
    getLeaseAgreementFurnishing: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLeaseAgreementFurnishing.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLeaseAgreementFurnishing.fulfilled, (state, action) => {
      state.getLeaseAgreementFurnishing = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchLeaseAgreementFurnishing.rejected, (state) => {
      state.loading = false;
    });
  },
});
// export const { loginclearApi } = loginSliceUser.actions;
export default getLeaseAgreementFurnishingSlice.reducer;
